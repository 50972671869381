import { createContext, useContext, useReducer, ReactNode } from "react";
import { AuthContext } from "./AuthContext";

type State = {
  chatId: string;
  collection: string;
  name: string;
  user: {
    // Define the properties of the user object based on your actual user data structure
    uid: string;
  };
};

type Action = {
  type: string;
  payload?: any; // Define the type for the payload property based on your specific use case
};

export const ChatContext = createContext<any>({});

export function ChatProvider({ children }: { children: ReactNode }) {
  const { currentUser } = useContext(AuthContext);
  const INITIAL_STATE: State = {
    chatId: "null",
    collection: "userChats", // "userChats" or "apartmentChats"
    name: "",
    user: { uid: "" },
  };

  const chatReducer = (state: State, action: Action) => {
    switch (action.type) {
      case "CHANGE_USER":
        return {
          user: action.payload,
          collection: action.payload.collection,
          name: action.payload.name,
          chatId:
            action.payload.collection === "userChats"
              ? currentUser.uid > action.payload.uid
                ? currentUser.uid + action.payload.uid
                : action.payload.uid + currentUser.uid
              : action.payload.collection === "apartmentChats"
              ? action.payload.uid
              : "null",
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(chatReducer, INITIAL_STATE);

  return (
    <ChatContext.Provider value={{ data: state, dispatch }}>
      {children}
    </ChatContext.Provider>
  );
}
