import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { CssBaseline, ThemeProvider, Toolbar } from "@mui/material";
import { NavBar } from "./components/Navbar";
import { Footer } from "./components/Footer";
import { useTheme } from "./core/theme";
import { Route, Routes } from "react-router-dom";
import {
  About,
  Admin,
  // Donate,
  DMs,
  Home,
  Login,
  Privacy,
  Profile,
  Register,
  Apartment,
  Settings,
  Terms,
} from "./pages/index";
import { AuthProvider } from "./core/AuthContext";
import { ChatProvider } from "./core/ChatContext";

function App() {
  const theme = useTheme();

  return (
    <div className="app-wrapper">
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <ChatProvider>
            <CssBaseline />
            <NavBar />
            <Toolbar />
            <div className="routes-wrapper">
              <Routes>
                <Route index element={<React.Suspense children={<Home />} />} />
                <Route
                  path="/search"
                  element={<React.Suspense children={<Home />} />}
                />
                <Route
                  path="/privacy"
                  element={<React.Suspense children={<Privacy />} />}
                />
                <Route
                  path="/about"
                  element={<React.Suspense children={<About />} />}
                />
                <Route
                  path="/apartments/:apartmentId"
                  element={<React.Suspense children={<Apartment />} />}
                />
                <Route
                  path="/profile"
                  element={
                    <React.Suspense children={<Profile indicator={"none"} />} />
                  }
                />
                <Route
                  path="/profile/:userId"
                  element={
                    <React.Suspense children={<Profile indicator={"id"} />} />
                  }
                />
                <Route
                  path="/terms"
                  element={<React.Suspense children={<Terms />} />}
                />
                <Route
                  path="/settings"
                  element={<React.Suspense children={<Settings />} />}
                />
                <Route
                  path="/admin"
                  element={<React.Suspense children={<Admin />} />}
                />
                <Route
                  path="/register/:referrer?"
                  element={<React.Suspense children={<Register />} />}
                />
                <Route
                  path="/login"
                  element={<React.Suspense children={<Login />} />}
                />
                <Route
                  path="/messages"
                  element={<React.Suspense children={<DMs />} />}
                />
              </Routes>
            </div>
            <Footer />
          </ChatProvider>
        </ThemeProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
