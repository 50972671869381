import * as React from "react";
import { auth } from "./firebase";
import { ApartmentProps } from "../pages/Apartment";
import { ProfileProps } from "../pages/Profile";
import axios, { AxiosProgressEvent, AxiosHeaders, AxiosResponse } from "axios";
import { AdminMetrics, FeedbackProps } from "../pages/Admin";

// #region TypeScript Definitions

type Type =
  | "CreateApartment"
  | "UpdateApartment"
  | "DeleteApartment"
  | "GetApartment"
  | "GetApartments"
  | "GetPossibleDuplicateApartments"
  | "GetApartmentsQueue"
  | "GetApartmentsList"
  | "SearchApartments"
  | "GetSimilarApartments"
  | "CreateReview"
  | "CreateProfile"
  | "GetProfile"
  | "GetApartmentNextProfile"
  | "GetProfiles"
  | "DeleteProfile"
  | "UpdateProfile"
  | "FollowProfile"
  | "UnfollowProfile"
  | "SearchProfiles"
  | "GetProfileList"
  | "UploadFile"
  | "CreateFeedback"
  | "GetFeedback";

type State<T extends Type> = {
  loading: boolean;
  errors: {
    [key in keyof Input<T> | "_"]?: string[];
  };
};

type Input<T extends Type> = T extends "CreateApartment"
  ? ApartmentProps
  : T extends "UpdateApartment"
  ? ApartmentProps
  : T extends "DeleteApartment"
  ? { apartmentId: string }
  : T extends "GetApartment"
  ? { apartmentId: string }
  : T extends "GetApartments"
  ? {}
  : T extends "GetApartmentsQueue"
  ? {}
  : T extends "GetApartmentsList"
  ? { userId: string }
  : T extends "GetPossibleDuplicateApartments"
  ? { place_id: string; lat: number; lng: number }
  : T extends "SearchApartments"
  ? { query: string; page: number }
  : T extends "GetSimilarApartments"
  ? {
      title: string;
      formatted_address: string;
    }
  : T extends "CreateReview"
  ? {
      apartmentId: string;
      specialty: number;
      quality: number;
      image: string;
      review: string;
    }
  : T extends "CreateProfile"
  ? ProfileProps & { referrerId?: string }
  : T extends "GetProfile"
  ? { profileId: string }
  : T extends "GetApartmentNextProfile"
  ? { apartmentId: string }
  : T extends "GetProfiles"
  ? {}
  : T extends "DeleteProfile"
  ? { profileId: string }
  : T extends "UpdateProfile"
  ? ProfileProps
  : T extends "SearchProfiles"
  ? { query: string; page: number }
  : T extends "GetProfileList"
  ? { profileList: string[] }
  : T extends "UploadFile"
  ? {
      file: File;
      title: string;
      prevImage: string;
      onUploadProgress: (progressEvent: any) => void;
    }
  : T extends "CreateFeedback"
  ? FeedbackProps
  : null;

export interface SiteData {
  id?: string;
  name?: string;
}

type FunctionResponseTypes<T extends Type> = T extends "GetProfiles"
  ? ProfileProps[]
  : T extends "GetProfileList"
  ? ProfileProps[]
  : T extends "GetProfile"
  ? ProfileProps
  : T extends "GetApartmentNextProfile"
  ? ProfileProps
  : T extends "GetApartment"
  ? ApartmentProps
  : T extends "GetApartments"
  ? ApartmentProps[]
  : T extends "GetApartmentsQueue"
  ? ApartmentProps[]
  : T extends "GetApartmentsList"
  ? ApartmentProps[]
  : T extends "GetPossibleDuplicateApartments"
  ? ApartmentProps[]
  : T extends "SearchApartments"
  ? ApartmentProps[]
  : T extends "GetSimilarApartments"
  ? ApartmentProps[]
  : T extends "UpdateProfile"
  ? ProfileProps
  : T extends "SearchProfiles"
  ? ProfileProps[]
  : T extends "UploadFile"
  ? { uploadUrl: String }
  : any;

export type Mutation<T extends Type> = State<T> & {
  commit: (input: Input<T>) => Promise<FunctionResponseTypes<T>>;
  setState: React.Dispatch<React.SetStateAction<State<T>>>;
};

// #endregion

const apiBaseUrl =
  process.env.REACT_APP_STAGE === "dev"
    ? "http://localhost:8080"
    : process.env.REACT_APP_PUBLIC_API_URL;

export function useMutation<T extends Type>(type: T): Mutation<T> {
  const [state, setState] = React.useState<State<T>>({
    loading: false,
    errors: {},
  });

  const commit = React.useCallback(
    async (input: Input<T>): Promise<FunctionResponseTypes<T>> => {
      try {
        setState((prev: State<T>) => ({ ...prev, loading: true, errors: {} }));

        const headers = new Headers({ [`Content-Type`]: `application/json` });
        const idToken = await auth.currentUser?.getIdToken();

        // NOTE: This is a hack to get around the issue of a new user signing in but recoil state is not updating
        if (idToken) {
          headers.set("Authorization", `Bearer ${idToken}`);
        }

        let res: Response;

        switch (type) {
          case "CreateApartment":
            res = await fetch(`${apiBaseUrl}/api/apartments`, {
              method: "POST",
              headers,
              body: JSON.stringify({ ...input }),
            });
            break;
          case "UpdateApartment": {
            const apartmentId = (input as ApartmentProps)._id;
            res = await fetch(
              `${apiBaseUrl}/api/apartments/update/${apartmentId}`,
              {
                method: "POST",
                headers,
                body: JSON.stringify({ update: { ...input } }),
              }
            );
            break;
          }
          case "DeleteApartment": {
            res = await fetch(`${apiBaseUrl}/api/apartments`, {
              method: "DELETE",
              headers,
              body: JSON.stringify({ ...input }),
            });
            break;
          }
          case "GetApartment": {
            const apartmentId = (input as { apartmentId: string }).apartmentId;
            res = await fetch(
              `${apiBaseUrl}/api/apartments/single/${apartmentId}`,
              {
                method: "GET",
                headers,
              }
            );
            break;
          }
          case "GetApartments": {
            res = await fetch(`${apiBaseUrl}/api/apartments`, {
              method: "GET",
              headers,
            });
            break;
          }
          case "GetApartmentsQueue": {
            res = await fetch(`${apiBaseUrl}/api/apartments/queue`, {
              method: "GET",
              headers,
            });
            break;
          }
          case "GetApartmentsList": {
            const inputCast = input as { userId: string[] };
            res = await fetch(
              `${apiBaseUrl}/api/apartments/saved/${inputCast.userId}`,
              {
                method: "GET",
                headers,
              }
            );
            break;
          }
          case "GetPossibleDuplicateApartments": {
            const inputCast = input as {
              place_id: string;
              lat: number;
              lng: number;
            };
            res = await fetch(
              `${apiBaseUrl}/api/apartments/place/${inputCast.place_id}/${inputCast.lat}/${inputCast.lng}`,
              {
                method: "GET",
                headers,
              }
            );
            break;
          }
          case "SearchApartments": {
            const inputCast = input as { query: string };
            res = await fetch(`${apiBaseUrl}/api/apartments/query`, {
              method: "POST",
              headers,
              body: JSON.stringify({ ...inputCast }),
            });
            break;
          }
          case "GetSimilarApartments": {
            const inputCast = input as {
              title: string;
              formatted_address: string;
            };
            res = await fetch(`${apiBaseUrl}/api/apartments/similar`, {
              method: "POST",
              headers,
              body: JSON.stringify({ ...inputCast }),
            });
            break;
          }
          case "CreateReview": {
            res = await fetch(`${apiBaseUrl}/api/apartments/review`, {
              method: "POST",
              headers,
              body: JSON.stringify({ ...input }),
            });
            break;
          }
          case "CreateProfile": {
            res = await fetch(`${apiBaseUrl}/api/users`, {
              method: "POST",
              headers,
              body: JSON.stringify({ ...input }),
            });
            break;
          }
          case "GetProfile": {
            const profileId = (input as { profileId: string }).profileId;
            res = await fetch(`${apiBaseUrl}/api/users/profile/${profileId}`, {
              method: "GET",
              headers,
            });
            break;
          }
          case "GetApartmentNextProfile": {
            const apartmentId = (input as { apartmentId: string }).apartmentId;
            res = await fetch(
              `${apiBaseUrl}/api/users/apartment/${apartmentId}`,
              {
                method: "GET",
                headers,
              }
            );
            break;
          }
          case "GetProfiles": {
            res = await fetch(`${apiBaseUrl}/api/users/blankSearch`, {
              method: "GET",
              headers,
            });
            break;
          }
          case "GetProfileList": {
            const inputCast = input as { profileList: string[] };
            res = await fetch(`${apiBaseUrl}/api/users/list`, {
              method: "POST",
              headers,
              body: JSON.stringify({ ...inputCast }),
            });
            break;
          }
          case "UpdateProfile": {
            const profileId = (input as ProfileProps)._id;
            res = await fetch(`${apiBaseUrl}/api/users/update/${profileId}`, {
              method: "POST",
              headers,
              body: JSON.stringify({ ...input }),
            });
            break;
          }
          case "DeleteProfile": {
            const profileId = (input as { profileId: string }).profileId;
            res = await fetch(`${apiBaseUrl}/api/users/${profileId}`, {
              method: "DELETE",
              headers,
            });
            break;
          }
          case "FollowProfile": {
            const castedInput = input as {
              profileId: string;
              followingId: string;
            };
            res = await fetch(
              `${apiBaseUrl}/api/users/follow/${castedInput.profileId}/${castedInput.followingId}`,
              {
                method: "PUT",
                headers,
              }
            );
            break;
          }
          case "UnfollowProfile": {
            const castedInput = input as {
              profileId: string;
              followingId: string;
            };
            res = await fetch(
              `${apiBaseUrl}/api/users/unfollow/${castedInput.profileId}/${castedInput.followingId}`,
              {
                method: "PUT",
                headers,
              }
            );
            break;
          }
          case "SearchProfiles": {
            const inputCast = input as { query: string };
            res = await fetch(`${apiBaseUrl}/api/users/query`, {
              method: "POST",
              headers,
              body: JSON.stringify({ ...inputCast }),
            });
            break;
          }
          case "UploadFile": {
            const castedInput = input as {
              file: File;
              title: string;
              prevImage: string;
              onUploadProgress: (progressEvent: AxiosProgressEvent) => void;
            };
            const formData = new FormData();
            formData.append("file", castedInput.file);
            formData.append("destFileName", castedInput.title);
            formData.append("prevImage", castedInput.prevImage);

            const config = {
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
              onUploadProgress: castedInput.onUploadProgress,
            };
            const axiosRes = await axios.post(
              `${apiBaseUrl}/api/util/image`,
              formData,
              config
            );
            res = new Response(JSON.stringify(axiosRes.data), {
              status: axiosRes.status,
              statusText: axiosRes.statusText,
              headers: {
                "content-type": "application/json",
              },
            });
            break;
          }
          case "CreateFeedback": {
            res = await fetch(`${apiBaseUrl}/api/feedback`, {
              method: "POST",
              headers,
              body: JSON.stringify({ ...input }),
            });
            break;
          }
          case "GetFeedback": {
            res = await fetch(`${apiBaseUrl}/api/feedback`, {
              method: "GET",
              headers,
            });
            break;
          }
          default:
            throw new TypeError();
        }

        if (res.ok) {
          const data =
            res.status === 204
              ? undefined
              : res.headers.get("content-type")?.includes("application/json")
              ? await res.json()
              : await res.text();
          setState((prev: State<T>) => ({
            ...prev,
            loading: false,
            errors: {},
          }));
          return data;
        } else {
          if (res.status === 401 || res.status === 403) {
            // TODO: Show sign in dialog
            // auth.signIn(...).then(...)
            console.log(res.status, res.statusText);
          }

          const errorRes = await res.json();
          const err = new Error(errorRes.message ?? "Something went wrong.");
          Object.defineProperty(err, "errors", { value: errorRes.errors });
          throw err;
        }
      } catch (err) {
        const message = (err as Error)?.message ?? "Something went wrong.";
        setState((prev: State<T>) => ({
          ...prev,
          loading: false,
          errors: (err as { errors: Record<string, string[]> })?.errors ?? {
            _: [message],
          },
        }));
        throw err;
      }
    },
    [type]
  );

  return React.useMemo(() => ({ ...state, commit, setState }), [state, commit]);
}
