/* SPDX-FileCopyrightText: 2014-present Kriasoft */
/* SPDX-License-Identifier: MIT */

import * as React from "react";

export const Home = React.lazy(() => import("./Home"));
export const Privacy = React.lazy(() => import("./Privacy"));
export const Terms = React.lazy(() => import("./Terms"));
export const About = React.lazy(() => import("./About"));
export const Settings = React.lazy(() => import("./Settings"));
export const Profile = React.lazy(() => import("./Profile"));
export const Apartment = React.lazy(() => import("./Apartment"));
export const Admin = React.lazy(() => import("./Admin"));
export const Register = React.lazy(() => import("./Register"));
export const Login = React.lazy(() => import("./Login"));
export const DMs = React.lazy(() => import("./DMs"));
// export const Donate = React.lazy(() => import("./Donate"));
